import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"

const BlogListTemplate = ({ data, location, pageContext }) => {
	const posts = data.allMarkdownRemark.edges
	const siteTitle = data.site.siteMetadata?.title || `Title`
	const { currentPage, numPages } = pageContext
	const isFirst = currentPage === 1
	const isLast = currentPage === numPages
	const prevPage = currentPage - 1 === 1 ? '/' : (currentPage - 1).toString()
	const nextPage = (currentPage + 1).toString()

	return (
		<Layout location={location} title={siteTitle}>
		  <SEO title="All posts" />
		  <Bio />
		  <ol style={{ listStyle: `none` }}>
				{posts.map( post  => {
					const title = post.node.frontmatter.title || post.node.fields.slug
					const featuredimage = post.node.frontmatter.featuredimage

					return (
						<li key={post.node.fields.slug}>
						  <article
						    className="post-list-item"
						    itemScope
						    itemType="http://schema.org/Article"
						  >
						    <header>
						      <h2>
						        <Link to={post.node.fields.slug} itemProp="url">
						          <span itemProp="headline">{title}</span>
						        </Link>
						      </h2>
					 				<small>{post.node.frontmatter.date}</small>
								</header>
						    {
									featuredimage && (
						        <Link to={post.node.fields.slug} itemProp="url">
											<Img
												fluid={featuredimage.src.childImageSharp.fluid}
											/>
										</Link>
									)
								}
								<section>
									<Link to={post.node.fields.slug} itemProp="url">
										<p
											dangerouslySetInnerHTML={{
												__html: post.node.frontmatter.description || post.node.excerpt,
											}}
											itemProp="description"
											style={{marginTop: "10px"}}
										/>
									</Link>
                </section>
              </article>
            </li>
					)
				})}
	    </ol>

		  {numPages>1 && (
				<ul
					style={{
						display: 'flex',
						flexWrap: 'wrap',
						justifyContent: 'space-between',
						alignItems: 'center',
						listStyle: 'none',
						padding: 0,
					}}
				>
					{!isFirst && (
						<Link to={prevPage} rel="prev">
							← Previous Page
						</Link>
					)}
					{Array.from({ length: numPages }, (_, i) => (
						<li
							key={`pagination-number${i + 1}`}
							style={{
								margin: 0,
							}}
						>
							<Link
								to={`/${i === 0 ? '' : i + 1}`}
								style={{
									textDecoration: 'none',
									color: i + 1 === currentPage ? '#ffffff' : '',
									background: i + 1 === currentPage ? '#007acc' : '',
								}}
							>
								{i + 1}
							</Link>
						</li>
					))}
					{!isLast && (
						<Link to={nextPage} rel="next">
							Next Page →
						</Link>
					)}
				</ul>
			)}
		</Layout>
	)
}

export default BlogListTemplate

export const blogListQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
	  site {
		  siteMetadata {
			  title
		  }
		}
	  allMarkdownRemark(
		  sort: { fields: [frontmatter___date], order: DESC }
			limit: $limit
			skip: $skip
		) {
		  edges {
			  node {
				  fields {
					  slug
				  }
					frontmatter {
					  title
						description
						featuredimage {
						  src {
							  childImageSharp {
								  fluid(maxWidth: 1024) {
									  ...GatsbyImageSharpFluid
									}
								}
							}
							alt
						}
				  }
				}
			}
		}
	}
`

